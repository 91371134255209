import logo from './logo.svg';
import './App.css';

function App() {
  window.open('https://scmable-accp.apps.ap-2a.mendixcloud.com/', '_self');
  return (
    <div className="App">
      <header className="App-header">
      </header>
    </div>
  );
}

export default App;
